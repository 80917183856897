/* eslint-disable import/prefer-default-export */
/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import 'core-js/modules/es6.set'; // react-dom
import 'core-js/modules/es6.map'; // react-dom
import intersectionObserver from 'intersection-observer';
import maybeLoadFonts from './src/scripts/fontLoading';

export function onClientEntry() {
  if (typeof IntersectionObserver === 'undefined') {
    intersectionObserver();
  }
}

export function onInitialClientRender() {
  maybeLoadFonts();
}

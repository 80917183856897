/* eslint-disable func-names */

function loadFonts() {
  document.fonts.load('1em "Scto Grotesk A Initial"').then(function() {
    document.documentElement.className += ' flSans1';
    Promise.all([
      document.fonts.load('400 1em "Scto Grotesk A"'),
      document.fonts.load('700 1em "Scto Grotesk A"'),
    ]).then(function() {
      document.documentElement.className += ' flSans2';
      document.fonts.load('1em "Spot Mono Initial"').then(function() {
        document.documentElement.className += ' flMono1';
        Promise.all([
          document.fonts.load('400 1em "Spot Mono"'),
          document.fonts.load('700 1em "Spot Mono"'),
        ]).then(function() {
          document.documentElement.className += ' flMono2';
          sessionStorage.fontsLoadedFoft = true;
        });
      });
    });
  });
}

function maybeLoadFonts() {
  if (window.sessionStorage.fontsLoadedFoft || !('fonts' in document)) {
    document.documentElement.className += ' flSans2 flMono2';
  } else {
    loadFonts();
  }
}

export default maybeLoadFonts;
